import axios from "axios";
import pool from ".";

export const chatApi = {
  getChatMessages: async (groupId) => {
    // console.log("getChatMessages");
    try {
      const promise = await axios
        .get(pool.serverUrl + pool.paths.getChatMessages.path + groupId)
        .then((r) => r.data);
        // console.log(promise);
        return [promise, null];
    } catch (error) {
      console.error(error);
      return [null, error];
    }
  },
  onSendMessage: async (packet) => {
    // console.log("onSendMessage");
    try {
      const promise = await axios
        .post(pool.serverUrl + pool.paths.sendChatMessage.path, packet)
        .then((r) => r.data);
        // console.log(promise);
        return [promise, null];
    } catch (error) {
      console.error(error);
      return [null, error];
    }
  }
};
