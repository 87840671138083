import React, { useEffect } from "react";
import { io } from "socket.io-client";
import MapView from "../map/MapView";
import qs from "query-string";
import { useSearchParams } from "react-router-dom";
import F404Component from "../_404/_404Component";
import { trackingApi } from "../../api/tracking.api";
import { Link } from "react-router-dom";
import GroupChat from "../chat/GroupChat";
import "./styles.css";
import { Header } from "./Header";
import { Footer } from "./Footer";
import pool from "../../api";
import { FloatingActionButton } from "./FloatingActionButton";
import { ChatWindowComponent } from "./ChatWindowComponent";
import { ChatControls } from "../../utils/ChatControls";
import { chatApi } from "../../api/chat.api";

const socket = io(pool.socketUrl);

export const Dashboard = (props) => {
  //usestate for maitaining polyline
  const [polyline, setPolyline] = React.useState([]);
  const [chatActive, setChatActive] = React.useState(false);
  const [chatMessages, setChatMessages] = React.useState([]);
  // usestate for trackingId
  const trackingId = React.useRef(null);

  // useState for tracking details
  const [trackingDetails, setTrackingDetails] = React.useState(null);

  // useRef for tracking details
  const trackingDetailsRef = React.useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  //useeffect for getting trackingId from query string
  useEffect(() => {
    console.log(searchParams.get('trackingId'));
    trackingId.current = searchParams.get("trackingId");
    askGeoLocation();
    // useeffect for getting tracking details every 50 seconds
    getTrackingDetailsById();

  }, []);


  // useEffect(askGeoLocation, []);

  useEffect(() => {
    let i = setInterval(askGeoLocation, 59000);
    socket.on("getGeolocation", (data) => {
      console.log("----socket getGeolocation-------",data[0]);
      setPolyline(
        data
          .map((x) => ({
            lat: Number(x.Lat),
            lng: Number(x.Long),
            trackingId: x.trackeMeId,
          }))
          .filter((x) => x.trackingId === trackingId.current)
      );
    });

    socket.on("channel_chat_broadcast", (data) => {
      ChatControls.handleChatBroadcastReceive(data, (message) => {
        // if senderId is same as message.senderId then abort
        if (message.senderId === localStorage.getItem("senderId")) return;
        setChatMessages((prevState) => [...prevState, message]);
      });
    });

    return () => {
      clearInterval(i);
      socket.disconnect();
    };
  }, []);

  
  // Method to ask for geolocation
  const askGeoLocation = () => {
    console.log("asking for", trackingId.current);
    socket.emit("askGeoLocation", {
      uuid: trackingId.current,
    });
  };

  useEffect(() => {
    let i = setInterval(getTrackingDetailsById, 50000);
    return () => {
      clearInterval(i);
    };
  }, []);

  // useState to check if chat is loaded
  const [loading, setLoading] = React.useState(false);
  // useEffect, initialize chat
  useEffect(() => {
    if (chatActive && loading === false) {
      (async () => {
        setLoading(true);
        await ChatControls.initiateChat(
          trackingDetailsRef.current.data.attachedEntities[0].entity.data.groupId,
          (serverResponse) => setChatMessages(serverResponse.data)
        ).then(() => setTimeout(() => setLoading(false), 1000));
      })();
    }
  }, [chatActive]);

  
  const getTrackingDetailsById = async () => {
    const [d, e] = await trackingApi.getTrackingDetailsById(trackingId.current);
    if (e) throw e;
    setTrackingDetails(d);
    // console.log("-------getTrackingDetailsById-------",d);
    trackingDetailsRef.current = d;
  };
  const checkIsChatActive = async () => {
    setChatActive(!chatActive);
  };

  const checkIsMapActive = async () => {
    setChatActive(false);
  };

  const onSendMessage = async (message) => {
    // Check if senderId is in localStorage - if not create one and add to localStorage
    if (!localStorage.getItem("senderId")) {
      // create senderId from random string
      const senderId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      localStorage.setItem("senderId", senderId);
    }
    // create packet from message data
    const packet = {
      message: {
        senderId: localStorage.getItem("senderId"),
        senderName: "Guest Session",
        type: "text",
        content: message,
      },
      groupId: trackingDetailsRef.current.data.attachedEntities[0].entity.data.groupId,
    };

    console.log('message-packet', packet);

    const [serverResponse, error] = await chatApi.onSendMessage({ ...packet });
    if (error) throw error;
    console.log(serverResponse);
    setChatMessages([...chatMessages, { ...packet.message, createdAt: Date.now() }]);
  };

  if (trackingId.current == null) {
    return <div>Loading ...</div>;
  }

  //TODO: Find if tracking id is an SOS
  // Get user details from tracking id
  const chatPosition = {
    position: "fixed",
    bottom: "0",
    right: "0",
    left: "0",
    backgroundColor: "aliceblue",
    padding: "10px",
  };

  return (
    <>
      <div style={{ height: "100vh" }} className="d-flex flex-column bg-light">
        <Header />
        <div className="" style={{ flex: 1 }}>
          <MapView
            path={polyline}
            trackingId={trackingId.current}
            trackingData={trackingDetailsRef.current}
          />
        </div>
        <FloatingActionButton
          src={!chatActive ? "fa fa-comments" : "fa fa-close"}
          onClick={checkIsChatActive}
        />
        {/* if isChatActive then show below */}
        <ChatWindowComponent
          // groupDetails={
          //   trackingDetailsRef.current &&
          //   trackingDetailsRef.current.attachedEntities[0].entity
          // }
          trackingId={trackingId.current}
          chatActive={chatActive}
          chatMessages={chatMessages}
          setChatMessages={setChatMessages}
          loading={loading}
          onSendMessage={onSendMessage}
          onCloseChat={checkIsChatActive}
        />
      </div>
    </>
  );
};
