import React, { useEffect, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import "../../assets/css/style.css";
import { TimeControls } from "../../utils/TimeControls";
import moment from "moment/moment";

function useWindowSize() {
  const [size, setSize] = useState([
    window.innerWidth < 601 ? window.innerHeight : window.innerHeight - 200,
    window.innerWidth - 64,
  ]);
  useEffect(() => {
    const handleResize = () => {
      // if width < 601 then use full height
      if (window.innerWidth < 601) {
        setSize([window.innerHeight, window.innerWidth - 64]);
      } else {
        setSize([window.innerHeight - 200, window.innerWidth]);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return size;
}

function GroupChat({ chatMessages }) {
  const [height] = useWindowSize();
  let scrollHeight = height - (132 + 54);

  // useRef to control scrollbar
  const scrollRef = React.useRef(null);

  // useEffect to scroll to bottom on chatMessages.length increase
  useEffect(() => {
    scrollRef.current.scrollToBottom();
  }, [chatMessages]);

  return (
    <>
      <div className="page-content  group-chat-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Scrollbar ref={scrollRef} style={{ height: scrollHeight }}>
                <div className="chat-message">
                  <span className="date">Group Description</span>
                </div>
                {chatMessages.map((chat, index) => {
                  if (chat.senderId !== localStorage.getItem("senderId")) {
                    return (
                      <div
                        className="user-chat sender d-flex flex-row"
                        key={index}
                      >
                        <div className="message sender-chat-bubble">
                          <small>
                            <b>{chat.senderName}</b>
                          </small>
                          <br />
                          <div style={{ fontSize: "1.1rem" }}>
                            { chat.type === 'Image' ? <a href={chat.content} target="_blank" ><img src={chat.content} style={{ width:80,height:80,margin:5 }} /></a> : chat.content }
                          </div>
                          <p className="date-time">
                            {moment(chat.createdAt).format(" hh:mm:a ")}
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="user-chat receiver d-flex flex-row flex-row-reverse"
                        key={index}
                      >
                        <div className="message text-light">
                          <div style={{ fontSize: "1.1rem" }}>
                            {chat.content}
                          </div>
                          <p className="date-time">
                            {moment(chat.createdAt).format(" hh:mm:a ")}
                          </p>
                        </div>
                      </div>
                    );
                  }
                })}
              </Scrollbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupChat;
