import React, { useEffect } from "react";
import { ChatControls } from "../../utils/ChatControls";
import { ChatScrollView } from "../chat/ChatScrollView";
import GroupChat from "../chat/GroupChat";
import { ChatBox } from "./ChatBox";

export function ChatWindowComponent({
  groupDetails,
  chatActive,
  onCloseChat,
  trackingId,
  chatMessages,
  setChatMessages,
  onSendMessage,
  loading,
}) {
  // if chat is not loaded then show loading
  if (loading === "loading") {
    return (
      <div className="bg-white chat-window d-flex flex-column justify-content-center align-items-center">
        <img
          style={{ width: 200 }}
          src="https://www.fugusau.edu.ng/wp-content/uploads/2021/01/loading-icon-animated-gif-19.gif"
          alt="Loading"
        />
      </div>
    );
  }

  if (loading === "idle") {
    return <></>;
  }

  return (
    chatActive && (
      <div className="bg-white chat-window">
        {/* <ChatScrollView /> */}
        <div className="chat-topBar">
          {/* Close button to the right */}
          <div className="d-flex flex-row justify-content-between">
            <div className="p-4">Chat</div>
            <div className="p-4 hover" onClick={onCloseChat}>
              <i className="fa fa-close" />
            </div>
          </div>
        </div>
        <div className="group-chat-height-control">
          <GroupChat chatMessages={chatMessages} />
        </div>
        <ChatBox onSendMessage={onSendMessage} />
      </div>
    )
  );
}
