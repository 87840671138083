const pool = {
  serverUrl: "https://server.sps.foxberry.link/v1/",
  socketUrl: "https://server.sps.foxberry.link",
  paths: {
    getTrackingDetailsById: {
      path: "trackme/getTrackingDetailsById",
      method: "POST",
    },
    getChatMessages: {
      path: "chat/",
      method: "GET",
    },
    sendChatMessage: {
      path: "chat/",
      method: "POST",
    },
  }
}

export default pool;