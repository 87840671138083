import logo from './logo.svg';
import './App.css';
import { Dashboard } from './features/app/Dashboard';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import F404Component from './features/_404/_404Component';
import ChatView from './features/chat/GroupChat';


function App() {
  return ( <BrowserRouter>
    <Routes>
      <Route path="/" element={<F404Component />} />
      <Route path="/trackme" element={<Dashboard />} />
      <Route path="/chat" element={ChatView} />
    </Routes>
  </BrowserRouter>)
}

export default App;
