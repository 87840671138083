import React from "react";

export function FloatingActionButton({ onClick, src }) {
  return (
    <div className="fab-chat-button" onClick={onClick}>
      {/* <img width={"28rem"} src={src} alt="chat icon" /> */}
      <i style={{
        fontSize: "2rem",
        color: "black",
      }} className={src} />
    </div>
  );
}
