import { chatApi } from "../api/chat.api";

export const ChatControls = {
  handleChatBroadcastReceive: (data, cb) => {
    console.log('broadcast', data);
    cb(data.message.data);
  },
  initiateChat: async (groupId, cb) => {
    const [chatMessages, error] = await chatApi.getChatMessages(groupId);
    console.log("----chat message data in chat controller------",chatMessages);
    if(error) throw error;
    cb(chatMessages) ;
  }
}