import React from "react";
import { Map, Polyline, Marker, GoogleApiWrapper } from "google-maps-react";
// import { geolocated } from "react-geolocated";

const MapView = ({ path, google, trackingData }) => {
  // if (!props.coords?.latitude) {
  //   return <div>Loading...</div>;
  // }\
  
  const [user] = React.useState(trackingData.data.user);
  const [assignedPolice]= React.useState(trackingData.dataP)
  const [assignedVolunteer,setAssignedVolunteer]= React.useState(trackingData.dataV)
  const [isSOSEnable] = React.useState(trackingData.data.isSOSEnable);
  const [viewBox, setViewBox] = React.useState(true);
  const [route,setRoute] = React.useState([])

  React.useEffect( () => {
    if(path?.length !== 0){
      setRoute(path)
    }
  },[path] )

  console.log("SOSENABLE",trackingData)

  return (
    <div>
      <Map
        initialCenter={
          route.length ? route[route.length - 1] : { lat: 18.5476268, lng: 73.820217 }
        }
        google={google}
        zoom={13}
      >
        {isSOSEnable ? (
          <Marker
            title={"Current Position Tracking Person"}
            name={"Tracking Person"}
            position={route[route.length - 1]}
            onClick={() => setViewBox(!viewBox)}
            icon={{
              url: "https://i.imgur.com/rZHfqWc.gif",
              anchor: new google.maps.Point(26, 48),
              scaledSize: new google.maps.Size(50, 48),
            }}
          />
        ) : (
          <Marker
            title={"Current Position Tracking Person"}
            name={"Tracking Person"}
            onClick={() => setViewBox(!viewBox)}
            position={route[route.length - 1]}
            icon={{
              url: "https://i.imgur.com/EUQxiaC.png",
              anchor: new google.maps.Point(18, 48),
              scaledSize: new google.maps.Size(42, 48),
            }}
          />
        )}
        
        <Polyline
          path={route}
          strokeColor="#06f"
          strokeOpacity={0.8}
          strokeWeight={3}
        />
      </Map>

      {viewBox && <ViewUserData user={user} AssignPolice={assignedPolice} AssignVolunteer={assignedVolunteer} isSOSEnable={isSOSEnable} />}
    </div>
  );
};

const ViewUserData = ({ user, isSOSEnable,AssignPolice,AssignVolunteer }) => {
  return (
    <div
      style={{
        padding: "30px",
        position: "fixed",
        right: 10,
        top: 80,
        backgroundColor: "#38434c",
        color:"#ffffff",
        borderRadius: "20px",
      }}
    >
      <h5>
        Tracking Details
        <br />
        <small>
          <small>{isSOSEnable ? "SOS" : "Tracking"}</small>
        </small>
      </h5>
      <h6>
        Name: {user.first_name ? user.first_name : "" } {user.last_name ? user.last_name : "" }
      </h6>
      <p>
        Email: {user.email ? user.email : ""}
        <br />
        Gender: {user.gender ? user.gender : ""}
        <br />
        Call: <a href={`tel:+91${user.mobile ? user.mobile :""}`}>
        <span style={{ color: 'white', }}>{user.mobile ? user.mobile:""}</span>
        </a>
      </p>
      {AssignPolice ? <div style={{ display:'flex',flexDirection:'column',marginTop:10 }}>
          <strong>Police Name: {AssignPolice.policeUserFirstName ? AssignPolice.policeUserFirstName:""}{AssignPolice.policeUserLastName ? AssignPolice.policeUserLastName:"" } </strong>
          <strong>Mobile Number : {AssignPolice.policeUserContactNo ? AssignPolice.policeUserContactNo : ""}</strong>
      </div> : ""}
      { AssignVolunteer ? <div style={{ display:'flex',flexDirection:'column',marginTop:5 }}>
          <strong>Volunteer Name: {AssignVolunteer ?.firstName ? AssignVolunteer.firstName : ""} {AssignVolunteer.lastName ? AssignVolunteer.lastName : ""}</strong>
          <strong>Mobile Number : {AssignVolunteer.contactNo ? AssignVolunteer.contactNo : ""}</strong>
      </div> :""}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCUIPTEbTazVFkX5LZrm25XlUENtscFrBE",
})(MapView);
