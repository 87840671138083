import React from "react";
import foxberryLogo from "../../assets/images/foxberry-logo.png";

export function Header() {
  return <div className="">
    <div className="header">
      <a href="//foxberry.in" target={"_blank"}>
        <img src={foxberryLogo} width="130" height="50" alt="Foxberry" />
      </a>
      <div className="avatarCircle">
        <span className="tallAndWide" title="Guest Session">
          GS
        </span>
      </div>
    </div>
  </div>;
}
