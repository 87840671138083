import React from "react";

export function ChatBox({ onSendMessage }) {
  // useState to manage new type message
  const [newMessage, setNewMessage] = React.useState("");

  // Method to clear Message
  const clearMessage = () => {
    setNewMessage("");
  };

  // Method to handle message change
  const handleMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  // Method to send Message
  const sendMessage = () => {
    // if message is empty, return
    if (newMessage.trim() === "") return;
    onSendMessage(newMessage);
    clearMessage();
  };

  return (
    <div>
      <div className="m-3 d-flex flex-row align-items-center justify-content-between">
        <div className="input-holder w-100 d-flex align-items-center justify-content-between">
          <div className="input-group common w-100 p-0 d-flex align-items-center justify-content-between">
            <textarea
              type="text"
              rows={newMessage.length > 32 ? 2 : 1}
              maxLength={256}
              className="form-control  shadow-none"
              placeholder="Type Message"

              value={newMessage}
              style={{
                marginRight: 10,
                fontSize: 20,
                padding: 10,
                paddingRight: 20,
                paddingLeft: 20,
                borderRadius: "50px",
                resize: 'none'
              }}
              onKeyDown={(e) => {
                // if key is 'Enter or Return' handleMessageChange
                if (e.keyCode === 13) {
                  e.preventDefault();
                  sendMessage();
                }

              }}
              onChange={handleMessageChange}
            ></textarea>
          </div>
        </div>
        <div>
          <div
            type="button"
            onClick={sendMessage}
            className="send-button d-flex align-items-center justify-content-center"
          >
            <i className="fa fa-paper-plane-o"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
