//Generic 404 React Component
// Language: javascript

export const F404Component = () => {
  return (
    <div>
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  );
}

export default F404Component;