import axios from "axios";
import { GoogleApiWrapper } from "google-maps-react";
import pool from ".";

export const trackingApi = {
  getTrackingDetailsById: async (trackingId) => {
    const promise = axios.post(
      pool.serverUrl + pool.paths.getTrackingDetailsById.path,
      {
        trackingId: trackingId,
      }
    );

    try {
      let d = await promise.then((r) => {
        // console.log(r.data);
        return r.data;
      });
      // console.log(d);
      return [d, null];
    } catch (error) {
      console.error(error);
      return [null, error];
    }
  },
};
